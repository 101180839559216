import axios from "@/axios";

export default async function createUser(user: CreateUser) {
    return await axios.post('/accounts/user', {
        first_name: user.firstName,
        last_name: user.lastName,
        email: user.email,
        phone_number: user.phone,
        password1: user.password1,
        password2: user.password2,
        role: user.role,
        department: user.department,
        function: user.func,
        access_code: user.accessCode
    }, { withCredentials: true });
}
