import {filtersService} from "@/store/services/filtersService";
import store from "@/store";
import {
    findAllSelectedItems,
    findMatchBetweenSelectedFiltersAndCurrentFilters,
    sortItems
} from "@/helpers/filtersHelper";
import axiosMain from "axios";

export const filtersModule = {
    namespaced: true,

    state: {
        isLoading: true,
        isOpen: true,
        status: {},
        firstFetch: true,
        currentRequest: null,
        currentFilters: {
            status: [],
            agencies: [],
            alert_type: [],
            sector_type: [],
            tags: [],
            subtype_classification: [],
            higher_order_alert_classification: [],
            classification: [],
            filing_type: [],
            in_federal_register: [],
            last_updated: [],
            comments_due: [],
            states: [],
            countries: []
        },
        selectedFilters: {
            status: [],
            agencies: [],
            alert_type: [],
            sector_type: [],
            tags: [],
            subtype_classification: [],
            higher_order_alert_classification: [],
            classification: [],
            filing_type: [],
            in_federal_register: [],
            last_updated: [],
            comments_due: [],
            states: [],
            countries: []
        }
    },

    getters: {
        isLoading: state => {
            return state.isLoading;
        },

        getCurrentFilters: state => {
            return state.currentFilters;
        },

        getSelectedFilters: state => {
            return state.selectedFilters;
        },

        getFiltersSidebarOpenState: state => {
            return state.isOpen;
        }
    },

    mutations: {
        SET_FILTER_RESULTS(state, value) {
            state.currentFilters = value;
        },

        SET_SIDEBAR_OPEN(state, value) {
            state.isOpen = value;
        },

        SET_SELECT_FILTER_RESULTS(state, value) {
            state.selectedFilters = value;
        },

        SET_FIRST_FETCHED(state, value) {
            state.firstFetch = value;
        },

        SET_LOADING_STATUS(state, payload) {
            state.isLoading = payload;
        },

        SET_CURRENT_REQUEST(state, payload) {
            state.currentRequest = payload;
        },

        RESET_ALL_SELECTED_FILTERS(state) {
            state.firstFetch = true;
            state.selectedFilters = {
                status: [],
                agencies: [],
                alert_type: [],
                sector_type: [],
                tags: [],
                subtype_classification: [],
                higher_order_alert_classification: [],
                classification: [],
                filing_type: [],
                in_federal_register: [],
                last_updated: [],
                comments_due: [],
                states: [],
                countries: []
            }
            state.currentFilters = {
                status: [],
                agencies: [],
                alert_type: [],
                sector_type: [],
                tags: [],
                subtype_classification: [],
                higher_order_alert_classification: [],
                classification: [],
                filing_type: [],
                in_federal_register: [],
                last_updated: [],
                comments_due: [],
                states: [],
                countries: []
            }
        },

        SELECT_FILTER_ITEM(state, payload) {
            let allItems = {...state.currentFilters};
            const itemIndex = allItems[payload.key].findIndex(x => x.label === payload.label);
            allItems[payload.key][itemIndex].selected = payload.selected;
            console.log('SELECT_FILTER_ITEM', itemIndex)
            console.log(allItems, "allItems")
            state.currentFilters = allItems;
        },

        SELECT_FILTER_ITEM_BACKUP(state, payload) {
            let allItems = {...state.selectedFilters};
            const itemIndex = allItems[payload.key].findIndex(x => x.label === payload.label);
            allItems[payload.key][itemIndex].selected = payload.selected;
            state.selectedFilters = allItems;
        },

        // Clear the filters that have a selected status of true
        CLEAR_SELECTED_FILTERS(state) {
            let allItems = state.currentFilters;
            let allSelectedItems = state.selectedFilters;
            const allKeys = ['agencies', 'alert_type', 'classification', 'sector_type', 'tags', 'subtype_classification', 'higher_order_alert_classification',
                'filing_type', 'in_federal_register', 'last_updated', 'comments_due', 'states', 'status', 'countries'
            ];
            allKeys.forEach(key => {
                if (allItems[key]) {
                    allItems[key] = [];
                }
                if (allSelectedItems[key]) {
                    allSelectedItems[key] = [];
                }
            });
        },

    },

    actions: {
        getFilters({commit, state}, payload = false) {

            commit('SET_LOADING_STATUS', true);

            // Cancel the request if it exists
            if (state.currentRequest) {
                state.currentRequest.cancel();
            }

            // Create a token for cancelling requests
            const cancelTokenSource = axiosMain.CancelToken.source();
            commit('SET_CURRENT_REQUEST', cancelTokenSource);

            const searchParameters = store.getters["search/getAllFilters"];
            const searchValue = store.getters["search/getSearchValue"];
            const allFiltersOnSidebar = store.getters["filters/getSelectedFilters"];
            const allSelectedItems = findAllSelectedItems(allFiltersOnSidebar);
            const filteredBy = store.getters['articles/getIsFilteredBy'];

            filtersService.getFiltersForQuery(cancelTokenSource, searchParameters, searchValue, allSelectedItems, filteredBy)
                .then(response => {

                    let responseData = response.data;
                    let updatedResults = findMatchBetweenSelectedFiltersAndCurrentFilters(responseData, state.selectedFilters);
                    if (responseData.agencies == undefined == true) {
                        commit('SET_FILTER_RESULTS', updatedResults);
                        commit('SET_CURRENT_REQUEST', null);
                        commit('SET_LOADING_STATUS', false);
                        return false;
                    }

                    // Converts the agencies that are joined together
                    // This is required as we are using a materialized view
                    let allAgenciesList = [];
                    const allAgencies = response.data.agencies;
                    if (allAgencies) {

                        allAgencies.forEach(item => {
                            if (item.label.includes(' || ')) {
                                const itemCount = parseInt(item.count.replace(/,/g, ''), 10);
                                const splitItems = item.label.split(' || ');
                                for (let i = 0; itemCount > i; i++) {
                                    splitItems.forEach(splitItem => {
                                        allAgenciesList.push(splitItem);
                                    })
                                }
                            } else {
                                const itemCount = parseInt(item.count.replace(/,/g, ''), 10);
                                for (let i = 0; itemCount > i; i++) {
                                    allAgenciesList.push(item.label);
                                }
                            }
                        });
                    }


                    let results = {};
                    allAgenciesList.forEach(i => {
                        results[i] = (results[i] || 0) + 1;
                    });

                    let finalResults = [];
                    for (const [key, value] of Object.entries(results)) {
                        if (payload) {
                            if (payload === key) {
                                finalResults.push({
                                    "label": key,
                                    "count": value.toLocaleString('en-US'),
                                    "selected": true
                                })
                            } else {
                                finalResults.push({
                                    "label": key,
                                    "count": value.toLocaleString('en-US'),
                                    "selected": false
                                })
                            }
                        } else {
                            finalResults.push({
                                "label": key,
                                "count": value.toLocaleString('en-US'),
                                "selected": false
                            })
                        }
                    }
                    finalResults = sortItems(finalResults, "count", true);
                    let sorted_finalResults = finalResults.sort((a, b) => parseInt(b.count.toString().split(',').join('')) > parseInt(a.count.toString().split(',').join('')) ? 1 : -1);
                    // let responseData = response.data;
                    responseData.agencies = sorted_finalResults;

                    if (state.firstFetch) {
                        commit('SET_SELECT_FILTER_RESULTS', responseData);
                        commit('SET_FIRST_FETCHED', false);
                    }

                    // let updatedResults = findMatchBetweenSelectedFiltersAndCurrentFilters(responseData, state.selectedFilters);

                    commit('SET_FILTER_RESULTS', updatedResults);
                    commit('SET_CURRENT_REQUEST', null);
                    commit('SET_LOADING_STATUS', false);
                })
                .catch(err => {

                    if (!state.currentRequest) {
                        store.dispatch('notifications/pushNotification', {
                            message: 'There was an error fetching the filters... Attempting to fetch again.',
                            type: 'danger'
                        });
                        commit('SET_LOADING_STATUS', false);
                    }

                    commit('SET_CURRENT_REQUEST', null);
                });
        },

        selectItem({commit}, payload) {
            commit('SET_LOADING_STATUS', true);
            const payloadItem = {...payload};
            commit('SELECT_FILTER_ITEM', payload);
            commit('SELECT_FILTER_ITEM_BACKUP', payloadItem);
            store.dispatch('articles/searchArticles');
        },

        clearFilters({commit}) {
            commit('SET_LOADING_STATUS', true);
            commit('SET_FIRST_FETCHED', true);
            commit('CLEAR_SELECTED_FILTERS');
            store.dispatch('articles/searchArticles');
        },

        clearAllFilters({commit}) {
            commit('CLEAR_SELECTED_FILTERS');
        },

        setLoadingStatusFiltersSidebar({commit}, value) {
            commit('SET_LOADING_STATUS', value);
        },

        resetAllSelectedFiltersSidebar({commit}) {
            commit('RESET_ALL_SELECTED_FILTERS');
        },

        openFiltersSidebar({commit}, value) {
            commit('SET_SIDEBAR_OPEN', value);
        }

    }
}