import axios from "@/axios";
import store from "@/store";
import router from "@/router";
import fileDownload from "js-file-download";
import {trackingHelper} from "@/main";

export const foldersModule = {
    namespaced: true,

    state: {
        showingModal: false,
        isLoading: false,

        allFolders: [],
        currentFolderData: {},
        downloadingReport: false

    },

    getters: {

        getFolderModalOpen: state => {
            return state.showingModal;
        },

        getIsLoadingFolders: state => {
            return state.isLoading;
        },

        getAllFolders: state => {
            return state.allFolders;
        },

        getCurrentFolderData: state => {
            return state.currentFolderData;
        },

        getIsDownloadReport: state => {
            return state.downloadingReport;
        }


    },

    mutations: {

        SET_MODAL_SHOWING(state, payload) {
            state.showingModal = payload;
        },

        SET_IS_LOADING(state, payload) {
            state.isLoading = payload;
        },

        SET_ALL_FOLDERS(state, payload) {
            state.allFolders = payload;
        },

        ADD_TO_FOLDERS(state, payload) {
            state.allFolders = state.allFolders.concat(payload);
        },

        SET_CURRENT_FOLDER_DATA(state, payload) {
            state.currentFolderData = payload;
        },

        SET_CURRENT_FOLDER_STATUS(state, payload) {
            const indexFolder = state.allFolders.findIndex(x => x.id === payload.id);
            state.allFolders[indexFolder] = payload
            // const newList = {...state.allFolders};
            // state.allFolders = newList;
        },

        REMOVE_ARTICLE_FROM_FOLDER(state, payload) {
            const articleIndex = state.currentFolderData.articles.findIndex(x => x.id === payload);
            const newList = {...state.currentFolderData};
            newList.articles.splice(articleIndex, 1);
            state.currentFolderData = newList;
        },

        RESET_FOLDER_DATA(state, payload) {
            state.currentFolderData = {};
        },

        SET_IS_DOWNLOAD_REPORT(state, payload) {
            state.downloadingReport = payload;
        }

    },

    actions: {
        setShowingModal({commit}, value) {
            commit('SET_MODAL_SHOWING', value);
        },

        fetchAllFolders({commit}) {
            commit("SET_IS_LOADING", true);

            axios.get('/folders/manage', {withCredentials: true})
                .then(response => {
                    response.data.folders.map((v, k) => {
                        v.isEdit = false;
                        return v
                    })
                    commit('SET_ALL_FOLDERS', response.data.folders);
                })
                .then(() => {
                    commit('SET_IS_LOADING', false);
                })
                .catch(() => {
                    store.dispatch('notifications/pushNotification', {
                        message: "There was an error fetching your folders. Please refresh and try again!",
                        type: "danger"
                    });
                });

        },
        update_folder_flag({commit}, payload) {
            commit('SET_CURRENT_FOLDER_STATUS', payload);
        },
        addNewFolder({commit}, folderName) {
            commit('SET_IS_LOADING', true);

            axios.post('/folders/manage', {
                name: folderName
            }, {withCredentials: true})
                .then(response => {
                    response.data.folder.isEdit = false;
                    commit('ADD_TO_FOLDERS', response.data['folder']);
                    commit('SET_IS_LOADING', false);
                    commit('SET_MODAL_SHOWING', false);
                    trackingHelper.trackEvent('createdFolder', {
                        folderId: response.data.folder.id,
                        folderName: response.data.folder.name
                    });
                    store.dispatch('notifications/pushNotification', {
                        message: `Created the folder "${folderName}" successfully!`,
                        type: "green"
                    });
                })
                .catch(() => {
                    commit('SET_IS_LOADING', false);
                    store.dispatch('notifications/pushNotification', {
                        message: "There was an error creating the folder. Please try again!",
                        type: "danger"
                    });
                })

        },

        deleteFolder({commit}, folderId) {
            commit('SET_IS_LOADING', true);
            axios.patch('/folders/manage', {
                folder_id: folderId
            }, {withCredentials: true})
                .then(response => {
                    store.dispatch('notifications/pushNotification', {
                        message: "Deleted the folder successfully!",
                        type: "green"
                    });
                    router.push({name: "AllFolders"});
                    trackingHelper.trackEvent('deleteFolder', {
                        folderId: folderId
                    });
                })
                .catch(() => {
                    store.dispatch('notifications/pushNotification', {
                        message: "There was an error deleting this folder",
                        type: "danger"
                    });
                    commit('SET_IS_LOADING', false);
                });
        },

        fetchFolderData({commit}, folderId) {
            commit("SET_IS_LOADING", true);

            axios.get(`/folders/manage/${folderId}`, {withCredentials: true})
                .then(response => {
                    commit('SET_CURRENT_FOLDER_DATA', response.data.folder);
                    commit("SET_IS_LOADING", false);
                })
                .catch(() => {
                    router.push({name: "AllFolders"});
                    store.dispatch('notifications/pushNotification', {
                        message: "There was an error fetching this folder!",
                        type: "danger"
                    });
                });

        },

        addArticleToFolder({commit}, {folderId, regalyticsAlertId}) {
            store.dispatch('notifications/pushNotification', {
                message: "Adding the Article to the folder...",
                type: "primary"
            });

            axios.post(`/folders/manage/${folderId}`, {
                folder_id: folderId,
                regalytics_alert_id: regalyticsAlertId
            }, {withCredentials: true})
                .then(() => {
                    store.dispatch('notifications/pushNotification', {
                        message: "Added the Article to the folder successfully!",
                        type: "green"
                    });
                })
                .catch(() => {
                    store.dispatch('notifications/pushNotification', {
                        message: "There was an error adding this Article to the folder!",
                        type: "danger"
                    });
                })
        },

        removeArticleFromFolder({commit}, {folderId, regalyticsAlertId}) {
            commit('REMOVE_ARTICLE_FROM_FOLDER', regalyticsAlertId);
            axios.patch(`/folders/manage/${folderId}`, {
                folder_id: folderId,
                regalytics_alert_id: regalyticsAlertId
            }, {withCredentials: true})
                .then(() => {
                    // Do nothing
                })
                .catch(() => {
                    store.dispatch('notifications/pushNotification', {
                        message: "There was an error removing this Article from the folder!",
                        type: "danger"
                    });
                });
        },

        downloadArticlesFromFolder({commit}, articleId) {
            commit('SET_IS_DOWNLOAD_REPORT', true);
            store.dispatch('notifications/pushNotification', {
                message: `Downloading all articles in the folder...`,
                type: 'primary'
            })
            axios.post(`/folders/download/${articleId}`, {}, {withCredentials: true})
                .then(response => {
                    const taskId = response.data.id;
                    store.dispatch('folders/checkReportStatus', {
                        articleId: articleId,
                        taskId: taskId
                    });
                })
                .catch(err => {
                    if (err.response.status === 426) {
                        store.dispatch('notifications/pushNotification', {
                            message: `You've already downloaded the max excel files for your account today!`,
                            type: 'danger'
                        }, {root: true});
                    } else {
                        store.dispatch('notifications/pushNotification', {
                            message: `An unknown error has occurred. Please try again.`,
                            type: 'danger'
                        }, {root: true});
                    }
                    commit('SET_IS_DOWNLOAD_REPORT', false);
                });
        },

        checkReportStatus({commit}, {articleId, taskId}) {
            axios.get(`/folders/download/${articleId}?task_id=${taskId}`, {withCredentials: true})
                .then(response => {
                    const taskStatus = response.data.task_status;

                    switch (taskStatus) {
                        case "SUCCESS":
                            store.dispatch('folders/downloadFile', {
                                articleId: articleId,
                                taskId: taskId
                            });
                            return false;
                        case "FAILURE":

                            store.dispatch('notifications/pushNotification', {
                                message: `There was an error downloading this folder`,
                                type: 'danger'
                            });
                            commit('SET_IS_DOWNLOAD_REPORT', false);
                            return false;
                        default:
                            // do-nothing
                            break;
                    }

                    setTimeout(function () {
                        store.dispatch('folders/checkReportStatus', {
                            articleId: articleId,
                            taskId: response.data.task_id
                        })
                    }, 3000);
                });
        },

        downloadFile({commit}, {articleId, taskId}) {
            axios.put(`/folders/download/${articleId}?task_id=${taskId}`, {}, {
                withCredentials: true,
                responseType: "blob"
            })
                .then(response => {
                    fileDownload(response.data, 'report.xlsx');
                    this.isDownloading = false;
                    store.dispatch('notifications/pushNotification', {
                        message: `Successfully downloaded all articles!`,
                        type: 'green'
                    });

                    trackingHelper.trackEvent('downloadedFolder', {
                        folderId: articleId
                    });
                    commit('SET_IS_DOWNLOAD_REPORT', false);
                });
        },

        resetArticleFolderData({commit}) {
            commit('RESET_FOLDER_DATA');
        },
        updateFolderName({commit}, payload) {
            axios.patch(`/folders/update/${payload.id}`, {name: payload.name}, {
                withCredentials: true,
            })
                .then(response => {
                    store.dispatch('notifications/pushNotification', {
                        message: `Successfully Updated Folder name`,
                        type: 'success'
                    }, {root: true});
                });
        }

    }

}
