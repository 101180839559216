import axios from "@/axios";

export default async function sendPasswordResetEmail(email: string) {
    // try {
        return await axios.post('/accounts/user/send/reset-password', {
            email: email
        }, { withCredentials: true });
    // } catch (e) {
    //     console.log("ASSSSSSSSSS", e)
    //     return e;
    // }
}
