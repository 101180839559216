function generateAgencies(agencies) {
    // Loop through all agencies
    let agency_list = [];
    let country_list = [];
    agencies.forEach(item => {
        agency_list.push({
            name: item.name,
            selected: false
        });

        item.states.forEach(item => {
            country_list.push({
                name: item.country.name,
                selected: false,
            })
        });
    });

    return [agency_list, country_list];
}

function generateFilterOptions(results) {

    // Creates the filters for Last Updated
    let lastUpdated = {
        today: false,
        lastWeek: false,
        lastMonth: false
    };

    let commentsDue = {
        tomorrow: false,
        nextWeek: false,
        nextMonth: false
    };

    let allItems = [...results];

    let allStatus = [];
    let allClassifications = [];
    let allFilingTypes = [];
    let allInFederalRegisters = [];
    let allAgencies = [];
    let allAlerts = [];
    let allCountries = [];

    // Used for debugging and viewing all articles and their values
    // console.log(allItems);

    // Loop through all results
    allItems.forEach(item => {

        // Find each agency in the item
        let generate_agencies_countries = generateAgencies(item.agencies);
        let agencies_res = generate_agencies_countries[0];
        let countries_res = generate_agencies_countries[1];
        allAgencies.push(...agencies_res);
        allCountries.push(...countries_res);

        // Check all alert_type(s) of all items
        allAlerts.push({
            name: item.alert_type,
            selected: false
        });

        // Creates an array of all Status'
        // It will be filtered later in the code
        allStatus.push({
            name: item.status,
            selected: false
        });

        // Creates an array of all Classifications
        // Will be filtered later in the code
        allClassifications.push({
            name: item.classification,
            selected: false
        });

        // Creates an array of all Filing Types
        // Will be filtered later in the code
        allFilingTypes.push({
            name: item.filing_type,
            selected: false
        });

        // Creates an array of all In Federal Registers
        // Will be filtered later in the code
        allInFederalRegisters.push({
            name: item.in_federal_register,
            selected: false
        });

    });


    // Remove all duplicates from the list of filters
    // Calculates the total count of all items as well
    // Sorts items based on name after calculating items
    allAgencies = sortItems(calculateMatchingItems(allAgencies), "name");
    allCountries = sortItems(calculateMatchingItems(allCountries), "name");
    allAlerts = sortItems(calculateMatchingItems(allAlerts), "name");
    allStatus = sortItems(calculateMatchingItems(allStatus), "name");
    allClassifications = sortItems(calculateMatchingItems(allClassifications), "name");
    allFilingTypes = sortItems(calculateMatchingItems(allFilingTypes), "name");
    allInFederalRegisters = sortItems(calculateMatchingItems(allInFederalRegisters), "name");

    // Builds the final object to return for filtering
    const finalFilter = {
        lastUpdated: lastUpdated,
        commentsDue: commentsDue,
        status: allStatus,
        classification: allClassifications,
        filingType: allFilingTypes,
        inFederalRegister: allInFederalRegisters,
        allAgencies: allAgencies,
        allAlerts: allAlerts,
        allCountries: allCountries
    };

    // console.log(finalFilter);
    return finalFilter;
}

// Removes all duplicates and creates a count total for matching results
function calculateMatchingItems(itemsArray) {
    return Object.values(itemsArray.reduce((r, e) => {
        let k = `${e.name}`;
        if (!r[k]) r[k] = {...e, count: 1}
        else r[k].count += 1;
        return r;
    }, {}))
}

// Sorts an item by a particular value
function sortItems(itemsArray, key, reverse = false) {
    if (reverse) {
        return itemsArray.sort((a, b) => {
            if (a[key] < b[key]) return 1;
            if (a[key] > b[key]) return -1;
            return 0;
        });
    } else {
        return itemsArray.sort((a, b) => {
            if (a[key] < b[key]) return -1;
            if (a[key] > b[key]) return 1;
            return 0;
        });
    }

}


// Finds the selected items for the current filters by a key
function returnSelectedItems(allFilters, key) {
    let final_list = [];
    const selectedItems = allFilters[key].filter(item => {
        if (item.selected) {
            return true;
        }
    });

    // Remove unnecessary items in the object
    // We only require/want the label for filtering on the backend
    selectedItems.forEach(item => {
        final_list.push(item.label);
    });

    return final_list;
}


// Creates a list of all items currently selected
// Required for the backend
function findAllSelectedItems(filters) {

    let allFilters = {...filters};

    let last_updated = [];
    let comments_due = [];
    let status = [];
    let classification = [];
    let filing_type = [];
    let in_federal_register = [];
    let alert_type = [];
    let states = [];
    let countries = [];
    let sector_type = [];
    let tags = [];
    let subtype_classification = [];
    let higher_order_alert_classification = [];
    let agencies = [];

    // If we currently do NOT have any filters loaded
    // Return an object with empty lists to prevent key errors on the backend
    if (filters.length <= 0) {
        return {
            last_updated,
            comments_due,
            status,
            classification,
            filing_type,
            in_federal_register,
            alert_type,
            states,
            countries,
            sector_type,
            tags,
            subtype_classification,
            higher_order_alert_classification,
            agencies
        }
    }

    // Check if the current key exists

    if (allFilters['last_updated']) {
        last_updated = returnSelectedItems(allFilters, 'last_updated');
    }

    if (allFilters['comments_due']) {
        comments_due = returnSelectedItems(allFilters, 'comments_due');
    }

    if (allFilters['status']) {
        status = returnSelectedItems(allFilters, 'status');
    }

    if (allFilters['classification']) {
        classification = returnSelectedItems(allFilters, 'classification');
    }

    if (allFilters['filing_type']) {
        filing_type = returnSelectedItems(allFilters, 'filing_type');
    }

    if (allFilters['in_federal_register']) {
        in_federal_register = returnSelectedItems(allFilters, 'in_federal_register');
    }

    if (allFilters['alert_type']) {
        alert_type = returnSelectedItems(allFilters, 'alert_type');
    }

    if (allFilters['states']) {
        states = returnSelectedItems(allFilters, 'states');
    }

    if (allFilters['countries']) {
        countries = returnSelectedItems(allFilters, 'countries');
    }
    if (allFilters['sector_type']) {
        sector_type = returnSelectedItems(allFilters, 'sector_type');
    }
    if (allFilters['tags']) {
        tags = returnSelectedItems(allFilters, 'tags');
    }
    if (allFilters['subtype_classification']) {
        subtype_classification = returnSelectedItems(allFilters, 'subtype_classification');
    }
    if (allFilters['higher_order_alert_classification']) {
        higher_order_alert_classification = returnSelectedItems(allFilters, 'higher_order_alert_classification');
    }

    if (allFilters['agencies']) {
        agencies = returnSelectedItems(allFilters, 'agencies');
    }


    return {
        last_updated,
        comments_due,
        status,
        classification,
        filing_type,
        in_federal_register,
        alert_type,
        states,
        countries,
        sector_type,
        tags,
        subtype_classification,
        higher_order_alert_classification,
        agencies
    }
}


// Filters items by selection
// Returns as an Array containing the name
function getSelectedItems(filterItem) {
    const selectedItems = filterItem.filter(x => x.selected);

    // Creates an Array of the selected items and returns
    let tempList = [];
    selectedItems.forEach(x => {
        tempList.push(x.name);
    });

    return tempList;
}

// Filters articles based on text or a value
// Returns an array containing the articles which meet the requirements
function filterArticlesByValue(allArticles, filterArray, value) {

    let filteredItems = [...allArticles];

    if (filterArray.length > 0) {
        filteredItems = filteredItems.filter(article => {
            if (filterArray.includes(article[value])) {
                return true;
            }
        });
        return filteredItems;
    }

    return allArticles;
}

function filterAllArticles(articlesArray, filterObjects) {

    let tempArray = [...articlesArray];
    let tempFilters = {...filterObjects};

    // Creates all lists for the filter options
    // These are the name of the item(s) select
    const selectedStatus = getSelectedItems(tempFilters.status);
    const selectedClassification = getSelectedItems(tempFilters.classification);
    const selectedFilingType = getSelectedItems(tempFilters.filingType);
    const selectedInFederalRegister = getSelectedItems(tempFilters.inFederalRegister);
    const selectedAgencies = getSelectedItems(tempFilters.allAgencies);
    const selectedAlertTypes = getSelectedItems(tempFilters.allAlerts);
    const selectedCountries = getSelectedItems(tempFilters.allCountries);

    // Creates a new filter list to sort through
    let filteredItems = [...articlesArray];

    // Filter articles by date
    filteredItems = filterByDate(filteredItems, tempFilters);

    // Filters articles based on all possible article values
    filteredItems = filterArticlesByValue(filteredItems, selectedStatus, "status");
    filteredItems = filterArticlesByValue(filteredItems, selectedClassification, "classification");
    filteredItems = filterArticlesByValue(filteredItems, selectedClassification, "classification");
    filteredItems = filterArticlesByValue(filteredItems, selectedFilingType, "filing_type");
    filteredItems = filterArticlesByValue(filteredItems, selectedInFederalRegister, "in_federal_register");
    filteredItems = filterArticlesByValue(filteredItems, selectedAlertTypes, "alert_type");
    filteredItems = filterArticlesByValue(filteredItems, selectedCountries, "countries");

    // Filters articles based on agencies
    if (selectedAgencies.length > 0) {
        filteredItems = filteredItems.filter(article => {
            for (let agency of article.agencies) {
                if (selectedAgencies.includes(agency.name)) {
                    return true;
                }
            }
        });
    }

    // console.log(filteredItems);
    return filteredItems;
}

// Calculate the total items for "Last Updated"
function calculateCountsByDate(allArticles) {
    let filteredArticlesByDate = [...allArticles];

    let today_count = 0;
    let week_count;
    let month_count;

    // Creates the seventh day from today
    // Seven days back
    let seventhDay = new Date();
    seventhDay.setDate(seventhDay.getDate() - 7);

    // Creates the 30th day from today
    // Thirty days back
    let thirtyDays = new Date();
    thirtyDays.setDate(thirtyDays.getDate() - 30);

    for (let item of filteredArticlesByDate) {
        const articlesDate = new Date(item.latest_update);
        const currentDate = new Date();

        // If the item matches Today
        if (articlesDate.getUTCDay() === currentDate.getUTCDay() && articlesDate.getUTCMonth() === currentDate.getUTCMonth() && articlesDate.getUTCFullYear() === currentDate.getUTCFullYear()) {
            today_count++;
        }
    }

    // Filter the items based on the last week
    week_count = filteredArticlesByDate.filter((d) => {
        return new Date(d.latest_update).getTime() >= seventhDay.getTime();
    }).length

    // Filter the items based on the last month
    month_count = filteredArticlesByDate.filter((d) => {
        return new Date(d.latest_update).getTime() >= thirtyDays.getTime();
    }).length

    return {
        today_count,
        week_count,
        month_count
    }
}

// Filters articles by dates
function filterByDate(allArticles, filterObjects) {
    let tempFilters = {...filterObjects};

    let allTimes = [];
    let filteredArticlesByDate = [...allArticles];

    // Creates the seventh day from today
    // Seven days back
    let seventhDay = new Date();
    seventhDay.setDate(seventhDay.getDate() - 7);

    // Creates the 30th day from today
    // Thirty days back
    let thirtyDays = new Date();
    thirtyDays.setDate(thirtyDays.getDate() - 30);

    let filteredData;

    // Check if the item timestamp matches today
    if (tempFilters.lastUpdated.today) {
        filteredData = [];
        for (let item of filteredArticlesByDate) {
            const articlesDate = new Date(item.latest_update);
            const currentDate = new Date();

            // If the item matches Today
            if (articlesDate.getUTCDay() === currentDate.getUTCDay() && articlesDate.getUTCMonth() === currentDate.getUTCMonth() && articlesDate.getUTCFullYear() === currentDate.getUTCFullYear()) {
                filteredData.push(item);
            }

        }
    } else {
        filteredData = [...allArticles];
    }


    // Filter the items based on the last week
    if (tempFilters.lastUpdated.lastWeek) {
        filteredData = filteredData.filter((d) => {
            return new Date(d.latest_update).getTime() >= seventhDay.getTime();
        })
    }

    // Filter the items based on the last month
    if (tempFilters.lastUpdated.lastMonth) {
        filteredData = filteredData.filter((d) => {
            return new Date(d.latest_update).getTime() >= thirtyDays.getTime();
        })
    }

    // Return all the results
    return filteredData;
}


// Calculates the matching selected items for use on the filters sidebar
// This will update the results from the back-end if they were in fact selected before
function findMatchBetweenSelectedFiltersAndCurrentFilters(currentFilters, selectedFilters) {
    let selectedAgencies = [];
    let selectedCountries = [];


    let selectedAlertTypes = [];
    let selectedClassification = [];
    let selectedFilingType = [];
    let selectedInFederalRegister = [];
    let selectedLastUpdated = [];
    let selectedCommentsDue = [];
    let selectedStatus = [];

    let selectedSectorTypes = [];
    let selectedTags = [];
    let selectedSubtype_classification = [];
    let selectedHigher_order_alert_classification = [];


    // Agencies
    if ("agencies" in selectedFilters) {
        let selectedAgencyObj = selectedFilters['agencies'].filter(x => x.selected);
        selectedAgencyObj.forEach(item => {
            selectedAgencies.push(item.label);
        });

    }
    // countries
    if ("countries" in selectedFilters) {
        let selectedCountryObj = selectedFilters['countries'].filter(x => x.selected);
        selectedCountryObj.forEach(item => {
            selectedCountries.push(item.label);
        })

    }
    // alert_type
    if ("alert_type" in selectedFilters) {
        let selectedAlertTypeObj = selectedFilters['alert_type'].filter(x => x.selected);
        selectedAlertTypeObj.forEach(item => {
            selectedAlertTypes.push(item.label);
        });

    }
    // classification
    if ("classification" in selectedFilters) {
        let selectedClassificationObj = selectedFilters['classification'].filter(x => x.selected);
        selectedClassificationObj.forEach(item => {
            selectedClassification.push(item.label);
        });
    }
    // filing_type
    if ("filing_type" in selectedFilters) {
        let selectedFilingTypeObj = selectedFilters['filing_type'].filter(x => x.selected);
        selectedFilingTypeObj.forEach(item => {
            selectedFilingType.push(item.label);
        });
    }
    // in_federal_register
    if ("in_federal_register" in selectedFilters) {
        let selectedInFederalRegisterObj = selectedFilters['in_federal_register'].filter(x => x.selected);
        selectedInFederalRegisterObj.forEach(item => {
            selectedInFederalRegister.push(item.label);
        });
    }
    // last_updated
    if ("last_updated" in selectedFilters) {
        let selectedLastUpdatedObj = selectedFilters['last_updated'].filter(x => x.selected);
        selectedLastUpdatedObj.forEach(item => {
            selectedLastUpdated.push(item.label);
        });
    }

    // comments_due
    if ("comments_due" in selectedFilters) {
        let selectedLastCommentsDueObj = selectedFilters['comments_due'].filter(x => x.selected);
        selectedLastCommentsDueObj.forEach(item => {
            selectedCommentsDue.push(item.label);
        });
    }
    // status
    if ("status" in selectedFilters) {
        let selectedStatusObj = selectedFilters['status'].filter(x => x.selected);
        selectedStatusObj.forEach(item => {
            selectedStatus.push(item.label);
        });
    }
    // sector_type
    if ("sector_type" in selectedFilters) {
        let selectedSectorTypeObj = selectedFilters['sector_type'].filter(x => x.selected);

        selectedSectorTypeObj.forEach(item => {
            selectedSectorTypes.push(item.label);
        })
    }

    //Tags
    if ("tags" in selectedFilters) {
        let selectedTagsObj = selectedFilters['tags'].filter(x => x.selected);
        selectedTagsObj.forEach(item => {
            selectedTags.push(item.label);
        })

    }
    //subtype_classification
    if ("subtype_classification" in selectedFilters) {
        let selectedSubtype_classificationObj = selectedFilters['subtype_classification'].filter(x => x.selected);
        selectedSubtype_classificationObj.forEach(item => {
            selectedSubtype_classification.push(item.label);
        })

    }
    //higher_order_alert_classification
    if ("higher_order_alert_classification" in selectedFilters) {
        let selectedHigher_order_alert_classificationObj = selectedFilters['higher_order_alert_classification'].filter(x => x.selected);
        selectedHigher_order_alert_classificationObj.forEach(item => {
            selectedHigher_order_alert_classification.push(item.label);
        })

    }


    let newSelectedFilters = {...currentFilters};

    if ("agencies" in selectedFilters) {
        if (selectedAgencies.length > 0) {
            if (newSelectedFilters.agencies) {
                newSelectedFilters['agencies'].forEach(item => {
                    if (selectedAgencies.includes(item.label)) {
                        item.selected = true;
                    }
                });
            }
        }

    }
    if ("countries" in selectedFilters) {
        if (selectedCountries.length > 0) {
            if (newSelectedFilters.countries) {
                newSelectedFilters['countries'].forEach(item => {
                    if (selectedCountries.includes(item.label)) {
                        item.selected = true;
                    }
                });
            }
        }

    }
    if ("sector_type" in selectedFilters) {
        if (selectedSectorTypes.length > 0) {
            if (newSelectedFilters.sector_type) {
                newSelectedFilters['sector_type'].forEach(item => {
                    if (selectedSectorTypes.includes(item.label)) {
                        item.selected = true;
                    }
                });
            }
        }

    }


    if ("tags" in selectedFilters) {
        if (selectedTags.length > 0) {
            if (newSelectedFilters.tags) {
                newSelectedFilters['tags'].forEach(item => {
                    if (selectedTags.includes(item.label)) {
                        item.selected = true;
                    }
                });
            }
        }
    }

    if ("subtype_classification" in selectedFilters) {
        if (selectedSubtype_classification.length > 0) {
            if (newSelectedFilters.subtype_classification) {
                newSelectedFilters['subtype_classification'].forEach(item => {
                    if (selectedSubtype_classification.includes(item.label)) {
                        item.selected = true;
                    }
                });
            }
        }

    }

    if ("higher_order_alert_classification" in selectedFilters) {
        if (selectedHigher_order_alert_classification.length > 0) {
            if (newSelectedFilters.higher_order_alert_classification) {
                newSelectedFilters['higher_order_alert_classification'].forEach(item => {
                    if (selectedHigher_order_alert_classification.includes(item.label)) {
                        item.selected = true;
                    }
                });
            }
        }

    }
    if ("alert_type" in selectedFilters) {
        if (selectedAlertTypes.length > 0) {
            if (newSelectedFilters.alert_type) {
                newSelectedFilters['alert_type'].forEach(item => {
                    if (selectedAlertTypes.includes(item.label)) {
                        item.selected = true;
                    }
                });
            }
        }

    }

    if ("classification" in selectedFilters) {
        if (selectedClassification.length > 0) {
            if (newSelectedFilters.classification) {
                newSelectedFilters['classification'].forEach(item => {
                    if (selectedClassification.includes(item.label)) {
                        item.selected = true;
                    }
                });
            }
        }

    }
    if ("filing_type" in selectedFilters) {
        if (selectedFilingType.length > 0) {
            if (newSelectedFilters.filing_type) {
                newSelectedFilters['filing_type'].forEach(item => {
                    if (selectedFilingType.includes(item.label)) {
                        item.selected = true;
                    }
                });
            }
        }

    }

    if ("in_federal_register" in selectedFilters) {
        if (selectedInFederalRegister.length > 0) {
            if (newSelectedFilters.in_federal_register) {
                newSelectedFilters['in_federal_register'].forEach(item => {
                    if (selectedInFederalRegister.includes(item.label)) {
                        item.selected = true;
                    }
                });
            }
        }
    }

    if ("last_updated" in selectedFilters) {
        if (selectedLastUpdated.length > 0) {
            if (newSelectedFilters.last_updated) {
                newSelectedFilters['last_updated'].forEach(item => {
                    if (selectedLastUpdated.includes(item.label)) {
                        item.selected = true;
                    }
                });
            }
        }
    }

    if ("comments_due" in selectedFilters) {
        if (selectedCommentsDue.length > 0) {
            if (newSelectedFilters.comments_due) {
                newSelectedFilters['comments_due'].forEach(item => {
                    if (selectedCommentsDue.includes(item.label)) {
                        item.selected = true;
                    }
                });
            }
        }
    }

    if ("status" in selectedFilters) {
        if (selectedStatus.length > 0) {
            if (newSelectedFilters.status) {
                newSelectedFilters['status'].forEach(item => {
                    if (selectedStatus.includes(item.label)) {
                        item.selected = true;
                    }
                });
            }
        }
    }


    return newSelectedFilters;
}


export {
    findAllSelectedItems,
    generateFilterOptions,
    filterAllArticles,
    calculateCountsByDate,
    sortItems,
    findMatchBetweenSelectedFiltersAndCurrentFilters
};