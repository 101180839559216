import { defaultSearchOptions } from "@/store/services/articleService";

export {
    resetUrlParams,
    addOrUpdateParameter,
    getAllUrlParamsOnLoad,
    getSearchValue,
    getSavedSearchName
};


// Adds a Key/Value pair to the URL parameters
function addOrUpdateParameter(name, value) {
    const url = new URL(document.location.href);
    let params = new URLSearchParams(url.search);

    // If the parameter already exists... Delete it and add it
    if (params.has(name)) {
        params.delete(name);
        params.append(name, value);
    } else {
        params.append(name, value);
    }

    // If the value provided is null, 0, or a blank string delete it from the URL
    if (value.trim().length <= 0) {
        params.delete(name);
    }

    history.replaceState(null, null, `?${params.toString()}`);
}

// Resets the URL on the current page to the default results
function resetUrlParams() {
    const url = new URL(document.location.href);
    const newUrl = `${url.origin}${url.pathname}`;

    history.replaceState(null, null, newUrl);
}

// Converts the string to a boolean
function stringToBoolean(stringValue) {
    switch (stringValue.toLowerCase().trim()) {
        case "true":
            return true;
        case "false":
            return false;
        default:
            // If this fails let javascript try to handle it
            return Boolean(stringValue);
    }
}

// Gets the search value from the URL parameters
function getSearchValue() {
    const url = new URL(document.location.href);
    let params = new URLSearchParams(url.search);

    let searchValue = '';

    params.forEach((value, key) => {
        // Decodes the URI to get the normal values
        value = decodeURI(value);

        switch (key) {
            case "searchValue":
                searchValue = value;
                break;
            default:
                // Do nothing
                break;
        }
    });

    return searchValue;
}

function getSavedSearchName() {
    const url = new URL(document.location.href);
    let params = new URLSearchParams(url.search);

    let savedSearchName = '';

    params.forEach((value, key) => {
        // Decodes the URI to get the normal values
        value = decodeURI(value);

        switch (key) {
            case "savedSearchName":
                savedSearchName = value;
                break;
            default:
                // Do nothing
                break;
        }
    });

    return savedSearchName;
}

// Gets all URL params on load
function getAllUrlParamsOnLoad(providedSearchParams = false) {
    let params;
    if (providedSearchParams) {
        params = new URLSearchParams(providedSearchParams);
    } else {
        const url = new URL(document.location.href);
        params = new URLSearchParams(url.search);
    }


    // Creates a new object of the params
    let tempSearchOptions = JSON.stringify(defaultSearchOptions);
    tempSearchOptions = JSON.parse(tempSearchOptions);

    // Temporary lists for States, Alerts, and Alert Types
    let statesList = [];
    let countriesList = [];
    let agenciesList = [];
    let alertTypes = [];
    let sector_type = [];
    let tags = [];
    let subtype_classification = [];
    let higher_order_alert_classification = [];
    let includeKeywords = [];
    let orIncludeKeywords = [];
    let excludeKeywords = [];
    let searchValue = '';

    // Will update the Search Options
    params.forEach((value, key) => {

        // Decodes the URI to get the normal values
        value = decodeURI(value);

        // Update the state for the tempSearchOptions
        // This will be sent to the Vuex store to update the search settings
        switch (key) {
            case "searchValue":
                searchValue = value;
                break;
            case "status":
                value = value.split('|');
                tempSearchOptions.status.new = stringToBoolean(value[0]);
                tempSearchOptions.status.updated = stringToBoolean(value[1]);
                break;
            case "classification":
                value = value.split('|');
                tempSearchOptions.classification.sro = stringToBoolean(value[0]);
                tempSearchOptions.classification.federal = stringToBoolean(value[1]);
                tempSearchOptions.classification.state = stringToBoolean(value[2]);
                tempSearchOptions.classification.territory = stringToBoolean(value[5]);
                tempSearchOptions.classification.county = stringToBoolean(value[4]);
                tempSearchOptions.classification.international = stringToBoolean(value[6]);
                tempSearchOptions.classification.multinational = stringToBoolean(value[3]);
                break;
            case "filingType":
                value = value.split('|');
                tempSearchOptions.filingType.single = stringToBoolean(value[0]);
                tempSearchOptions.filingType.joint = stringToBoolean(value[1]);
                break;
            case "inFederalRegister":
                value = value.split('|');
                tempSearchOptions.inFederalRegister.yes = stringToBoolean(value[0]);
                tempSearchOptions.inFederalRegister.not_yet = stringToBoolean(value[1]);
                tempSearchOptions.inFederalRegister.not_expected = stringToBoolean(value[2]);
                break;
            case "states":
                value = value.split('|');
                for (let item of value) {
                    statesList.push({ name: item });
                }
                tempSearchOptions.state = statesList;
                break;
            case "countries":
                value = value.split('|');
                for (let item of value) {
                    countriesList.push({ name: item });
                }
                tempSearchOptions.country = countriesList;
                break;
            case "agencies":
                value = value.split('|');
                for (let item of value) {
                    agenciesList.push({ name: item });
                }
                tempSearchOptions.agency = agenciesList;
                break;
            case "alertTypes":
                value = value.split('|');
                for (let item of value) {
                    alertTypes.push({ name: item });
                }
                tempSearchOptions.alertType = alertTypes;
                break;

            case "sector_type":
                value = value.split('|');
                for (let item of value) {
                    sector_type.push({ name: item });
                }
                tempSearchOptions.sector_type = sector_type;
                break;

            case "tags":
                value = value.split('|');
                for (let item of value) {
                    tags.push({ name: item });
                }
                tempSearchOptions.tags = tags;
                break;


            case "subtype_classification":
                value = value.split('|');
                console.log(value)
                for (let item of value) {
                    subtype_classification.push({ name: item });
                }
                tempSearchOptions.subtype_classification = subtype_classification;
                break;
            case "higher_order_alert_classification":
                value = value.split('|');
                console.log(value)
                for (let item of value) {
                    higher_order_alert_classification.push({ name: item });
                }
                tempSearchOptions.higher_order_alert_classification = higher_order_alert_classification;
                break;
            case "includes":
                value = value.split('|');
                for (let item of value) {
                    includeKeywords.push(item);
                }
                tempSearchOptions.includes = includeKeywords;
                break;
            case "orIncludes":
                value = value.split('|');
                for (let item of value) {
                    orIncludeKeywords.push(item);
                }
                tempSearchOptions.orIncludes = orIncludeKeywords;
                break;
            case "excludes":
                value = value.split('|');
                for (let item of value) {
                    excludeKeywords.push(item);
                }
                tempSearchOptions.excludes = excludeKeywords;
                break;
            case "sec_release_number":
                tempSearchOptions.sec_release_number = value;
                break;
            case "docket_file_number":
                tempSearchOptions.docket_file_number = value;
                break;
            case "order_notice":
                tempSearchOptions.order_notice = value;
                break;
            case "regAlyticsAlertID":
                tempSearchOptions.regAlyticsAlertId = value;
                break;

            case "federalRegisterNumber":
                tempSearchOptions.federalRegisterNumber = value;
                break;
            case "latestUpdateStart":
                tempSearchOptions.latestUpdate.start = value;
                break;
            case "latestUpdateEnd":
                tempSearchOptions.latestUpdate.end = value;
                break;
            case "originallyPublishedStart":
                tempSearchOptions.originallyPublished.start = value;
                break;
            case "originallyPublishedEnd":
                tempSearchOptions.originallyPublished.end = value;
                break;
            case "proposedCommentsDueStart":
                tempSearchOptions.proposedCommentsDue.start = value;
                break;
            case "proposedCommentsDueEnd":
                tempSearchOptions.proposedCommentsDue.end = value;
                break;
            case "ruleEffectiveStart":
                tempSearchOptions.ruleEffective.start = value;
                break;
            case "ruleEffectiveEnd":
                tempSearchOptions.ruleEffective.end = value;
                break;
            case "publishedInFederalReserveStart":
                tempSearchOptions.publishedInFederalReserve.start = value;
                break;
            case "publishedInFederalReserveEnd":
                tempSearchOptions.publishedInFederalReserve.end = value;
                break;
            default:
                // Do nothing
                break;
        }

    });

    return tempSearchOptions;
}