import {getAllUserCollections, getAllUserCollectionsCounts, getCuratedCollections,getCompanyCollections} from "@/store/services/articleCollectionsService";
import axios from '@/axios';
import store from '@/store';
import {getAllUrlParamsOnLoad, getSearchValue} from "@/helpers/urlParamsHelper";

export const collectionsModule = {
    namespaced: true,

    state: {
        isOpen: false,
        showingModal: false,
        releaseModal: false,
        addingCollection: false,
        isLoading: true,
        countLoad: true,
        allCollections: [],
        allCuratedCollections: [],
        allCompanyCollections: [],
        collectionItem: null

    },

    getters: {

        returnAllCollections: state => {
            return state.allCollections;
        },

        returnAllCuratedCollections: state => {
            return state.allCuratedCollections;
        },
        returnAllCompanyCollections: state => {
            return state.allCompanyCollections;
        },

        getIsShowingModal: state => {
            return state.showingModal;
        },

        returnIfOpen: state => {
            return state.isOpen;
        },

        returnAddingCollection: state => {
            return state.addingCollection;
        },

        returnIsLoading: state => {
            return state.isLoading;
        },

        returnCountIsLoading: state => {
            return state.countLoad;
        },
        returnCollectionItem: state => {
            return state.collectionItem;
        },

    },

    mutations: {

        SWAP_COLLECTION_OPEN(state) {
            state.isOpen = !state.isOpen;
        },

        SWAP_SHOWING_MODAL(state, payload) {
            state.showingModal = payload;
        },
        SWAP_RELEASE_MODAL(state, payload) {
            state.releaseModal = payload;
        },

        SWAP_ADDING_COLLECTION(state) {
            state.addingCollection = !state.addingCollection;
        },

        UPDATE_ALL_COLLECTIONS(state, payload) {
            state.allCollections = payload;
        },

        UPDATE_ALL_CURATED_COLLECTIONS(state, payload) {
            state.allCuratedCollections = payload;
        },
        UPDATE_ALL_COMPANY_COLLECTIONS(state, payload) {
            state.allCompanyCollections = payload;
        },

        UPDATE_ALL_COLLECTIONS_COUNTS(state, payload) {
            state.allCollections = state.allCollections.map((v, k) => {
                let count_data = payload.filter(resp => resp.id == v.id);
                v.count = count_data[0].count.toLocaleString()
                return v
            })
        },


        UPDATE_IS_LOADING(state, payload) {
            state.isLoading = payload;
        },

        UPDATE_COUNT_LOAD(state, payload) {
            state.countLoad = payload;
        },
        UPDATE_COLLECTION_ITEM(state, payload) {
            state.collectionItem = payload;
        },


    },

    actions: {

        openOrCloseCollection({commit}) {
            commit('SWAP_COLLECTION_OPEN');
        },

        setShowingModal({commit}, value) {
            commit('SWAP_SHOWING_MODAL', value);
        },

        setReleaseNotesModal({commit}, value) {
            commit('SWAP_RELEASE_MODAL', value);
        },

        setCountLoad({commit}, value) {
            commit('UPDATE_IS_LOADING', value);
        },


        swapAddingCollection({commit}) {
            commit('SWAP_ADDING_COLLECTION');
        },

        setIsLoading({commit}, value) {
            commit('UPDATE_IS_LOADING', value);
        },

        fetchAllUserCollections({commit}) {
            commit('UPDATE_IS_LOADING', true);
            getAllUserCollections()
                .then(response => {
                    commit('UPDATE_ALL_COLLECTIONS', response.data);
                    commit('UPDATE_IS_LOADING', false);
            });
        },

        fetchAllCuratedCollections({commit}) {
            commit('UPDATE_IS_LOADING', true);
            getCuratedCollections()
                .then(response => {
                    commit('UPDATE_ALL_CURATED_COLLECTIONS', response.data);
                    commit('UPDATE_IS_LOADING', false);
            });

        },
        fetchAllCompanyCollections({commit}) {
            commit('UPDATE_IS_LOADING', true);
            getCompanyCollections()
                .then(response => {
                    commit('UPDATE_ALL_COMPANY_COLLECTIONS', response.data);
                    commit('UPDATE_IS_LOADING', false);
                });

        },
        fetchAllCounts({commit}) {
            commit('UPDATE_COUNT_LOAD', true)
        },
        setCollectionItem({commit}, value) {
            commit('UPDATE_COLLECTION_ITEM', value)
        },
        // Will check for a pinned collection/saved search and will redirect
        // the user to their saved search if it exists
        checkForPinnedArticleRedirect({commit}) {
            commit('UPDATE_IS_LOADING', true);
            axios.get('/collections/get-pinned', {withCredentials: true})
                .then(response => {
                    if (response.data.success) {
                        let url = new URL(window.location.href);
                        url.search = response.data.pinnedUrl;

                        // Build new URL required
                        let pinnedUrl = `${url.origin}${url.pathname}${url.search}&savedSearchName=${response.data.name}`;
                        history.replaceState('', '', pinnedUrl);

                        // Find all parameters and update the state
                        const searchValues = getAllUrlParamsOnLoad();
                        const searchValue = getSearchValue();
                        store.dispatch('search/updateAllSearchOptions', searchValues);
                        store.dispatch('search/updateSearchValue', searchValue);
                        store.dispatch('search/setSavedSearchName', response.data.name);

                        // Fetch all articles based on search options
                        store.dispatch('articles/searchArticles');
                    } else {
                        store.dispatch('application/setApplicationLoadingState', false);
                        store.dispatch('articles/getArticles');
                    }
                })
                .catch(err => {
                    store.dispatch('application/setApplicationLoadingState', false);
                    store.dispatch('articles/getArticles');
                    console.log('Error fetching a pinned collection');
                    console.log(err);
                });
        },

        // Removes a Users collection
        // Parameter required is the ID of the collection
        removeUserCollectionById({commit}, value) {
            commit('UPDATE_IS_LOADING', true);
            axios.post('/collections/remove', {itemId: value}, {withCredentials: true})
                .then(() => {
                    getAllUserCollections()
                        .then(response => {
                            commit('UPDATE_ALL_COLLECTIONS', response.data);
                            commit('UPDATE_IS_LOADING', false);
                        });
                });
        },


    }

};
