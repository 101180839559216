<template>
  <div id="app">
    <div class="custom-loading-overlay">
      <transition name="fade">
        <loading-overlay v-if="getIsLoading"/>
      </transition>
    </div>
    <router-view/>
  </div>
</template>

<script>
import LoadingOverlay from "@/layouts/components/LoadingOverlay";
import {mapGetters} from "vuex";
export default {
  components: {LoadingOverlay},

  computed: {
    ...mapGetters('application', ['getIsLoading'])
  }

}
</script>

<style lang="scss" scoped>
.custom-loading-overlay {
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    transition: opacity 0.5s ease;
    opacity: 0;
  }
}
</style>