import {VerifyEmail} from "@/types/account/VerifyEmail";
import axios from "@/axios";


export default async function verifyEmail(tokenVerification: VerifyEmail) {
    try {
        return await axios.patch('/accounts/user/verify-email', {
            uid: tokenVerification.uid,
            token: tokenVerification.token
        }, { withCredentials: true });
    } catch (e) {
        return e;
    }
}
