import store from '@/store';
import {getAllUrlParamsOnLoad, getSearchValue} from "@/helpers/urlParamsHelper";
import axios from "@/axios";
import { trackingHelper } from "@/main";

export const editSavedSearchModule = {
    namespaced: true,

    state: {

        showingModal: false,
        isLoading: false,
        savedItemDetails: {},
        previousState: {},
        previousSearchValue: '',
        previousUrl: '',

    },

    getters: {

        getIsLoading: state => {
            return state.isLoading;
        },

        getIsShowingModal: state => {
            return state.showingModal;
        },

        getSavedItemDetails: state => {
            return state.savedItemDetails;
        },

        getSavedSearchName: state => {
            return state.savedItemDetails.name;
        }

    },

    mutations: {

        SET_IS_LOADING(state, payload) {
            state.isLoading = payload;
        },

        SWAP_SHOWING_MODAL(state, payload) {

            // If we are closing the modal
            // Reset the state and URL parameters to what they were before
            if (!payload) {
                window.history.replaceState(null, null, `/dashboard/${state.previousUrl}`);
                store.dispatch('search/updateAllSearchOptions', state.previousState);
                store.dispatch('search/updateSearchValue', state.previousSearchValue);

                state.previousSearchValue = '';
                state.previousState = {};
                state.previousUrl = '';
            }

            state.showingModal = payload;
        },

        // Sets the saved item details to be using in the modal/url parameters as well
        SET_SAVED_ITEM_DETAILS(state, payload) {
            const searchValues = getAllUrlParamsOnLoad(payload.parameters);
            const searchValue = getSearchValue();
            store.dispatch('search/updateAllSearchOptions', searchValues);
            store.dispatch('search/updateSearchValue', searchValue);
            window.history.replaceState(null, null, `/dashboard/${payload.parameters}`);
            state.savedItemDetails = payload;
        },

        // Sets the previous state before we open the modal
        SET_PREVIOUS_STATE(state) {
            const searchValues = getAllUrlParamsOnLoad();
            const searchValue = getSearchValue();
            const previousUrl = new URL(document.location.href);
            state.previousState = searchValues;
            state.previousSearchValue = searchValue;
            state.previousUrl = previousUrl.search;
        },

        // Sets the Saved Search Name
        SET_SAVED_SEARCH_NAME(state, payload) {
            state.savedItemDetails.name = payload;
        }

    },

    actions: {

        setIsLoading({commit}, value) {
            commit('SET_IS_LOADING', value);
        },

        setShowingModal({commit}, value) {
            commit('SWAP_SHOWING_MODAL', value);
        },

        setPreviousState({commit}) {
            commit('SET_PREVIOUS_STATE');
        },

        setSavedItemDetails({commit}, value) {
            commit('SET_SAVED_ITEM_DETAILS', value);
        },

        setSavedSearchName({commit}, value) {
            commit('SET_SAVED_SEARCH_NAME', value);
        },

        updateSavedSearch({commit, state}) {
            commit('SET_IS_LOADING', true);
            // Fetch the search parameters from the URL
            const url = new URL(window.location.href);
            let searchParameters;
            if (url.search.length >= 2) {
                let tempParams = new URLSearchParams(url.search);
                tempParams.delete('savedSearchName');
                searchParameters = `?${tempParams.toString()}`;
            }

            // Updates a saved search
            axios.put('/collections/create', {
                id: state.savedItemDetails.id,
                name: state.savedItemDetails.name,
                parameters: url.search.length >= 2 ? searchParameters : "default"
            }, { withCredentials: true })
                .then(() => {
                    store.dispatch('notifications/pushNotification', {message: "Updated your saved search successfully!", type: "green"});
                    commit('SET_IS_LOADING', false);

                    // Tracks updating a saved search
                    const allFilters = store.getters['search/getAllFilters'];
                    const finalFilters = {
                        ...allFilters,
                        savedSearchId: state.savedItemDetails.id,
                        savedSearchName: state.savedItemDetails.name
                    }
                    trackingHelper.trackEvent('updateSavedSearch', finalFilters);

                    store.dispatch('collections/fetchAllUserCollections');
                    store.dispatch('collections/fetchAllCounts')
                })
                .catch(() => {
                    store.dispatch('notifications/pushNotification', {message: "There was an error updating this saved search! Please try again.", type: "danger"});
                    commit('SET_IS_LOADING', false);
                });
        },

    }

};
