import {articleService, logSearchHistory} from "@/store/services/articleService";
import axios from "@/axios";


export const tooltipModule = {
    namespaced: true,

    state: {
        tooltips: []

    },

    getters: {



    },

    mutations: {
        UPDATE_TOOLTIPS(state, payload) {
            state.tooltips=payload;
        },


    },

    actions: {

        update_tooltips({commit}) {
            return axios.get(`/articles/fetch-tooltips`, { withCredentials: true })
                .then(response => {
                    let resp = response.data.results
                    localStorage.setItem('toolTips',JSON.stringify(response.data.results))
                    commit('UPDATE_TOOLTIPS', resp);
                });

        }

    }

};
