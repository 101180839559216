export const messageModule = {
    namespaced: true,

    state: {
        message: {
            type: null,
            text: null
        }
    },

    getters: {
        isMessage: state => {
            return state.message.text !== null;
        },

        getMessage: state => {
            return state.message;
        }
    },

    actions: {
        info({commit}, text) {
            commit('INFO', text);
        },

        warning({commit}, text) {
            commit('WARNING', text);
        },

        error({commit}, text) {
            commit('ERROR', text);
        },

        clear({commit}) {
            commit('CLEAR');
        }
    },

    mutations: {
        INFO(state, text) {
            state.message = {type: 'alert-info', text: text};
        },

        WARNING(state, text) {
            state.message = {type: 'alert-warning', text: text};
        },

        ERROR(state, text) {
            state.message = {type: 'alert-danger', text: text};
        },

        CLEAR(state) {
            state.message = {type: null, text: null};
        }
    }
}
