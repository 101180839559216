import {VerifyEmail} from "@/types/account/VerifyEmail";
import axios from "@/axios";


export default async function changeLicense(accessCode: string) {
    try {
        return await axios.patch('/accounts/user/upgrade', {
            access_code: accessCode
        }, { withCredentials: true });
    } catch (e) {
        return e;
    }
}
