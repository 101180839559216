import {Commit} from "vuex";


export const applicationModule = {
    namespaced: true,

    state: {
        isLoading: false as boolean,
        isMobile: false as boolean,
        windowHeight: 0 as number,
        showingSupportModal: false as boolean,
        showingAccountModal: false as boolean
    },

    getters: {

        getIsLoading: (state: { isLoading: boolean }) => {
            return state.isLoading;
        },

        getSupportModal: (state: { showingSupportModal: boolean }) => {
            return state.showingSupportModal;
        },

        getAccountModal: (state: { showingAccountModal: boolean }) => {
            return state.showingAccountModal;
        },

        getIsMobile: (state: { isMobile: boolean }) => {
            return state.isMobile;
        },

        getWindowHeight: (state: { windowHeight: number }) => {
            return state.windowHeight;
        }

    },

    mutations: {
        SWAP_LOADING_STATE(state: { isLoading: boolean }, value: boolean) {
            state.isLoading = value;
        },

        SET_SUPPORT_MODAL(state: { showingSupportModal: boolean }, value: boolean) {
            state.showingSupportModal = value;
        },

        SET_ACCOUNT_MODAL(state: { showingAccountModal: boolean }, value: boolean) {
            state.showingAccountModal = value;
        },

        SET_IS_MOBILE(state: { isMobile: boolean }, value: boolean) {
            state.isMobile = value;
        },

        SET_WINDOW_HEIGHT(state: { windowHeight: number }, value: number) {
            state.windowHeight = value;
        }

    },

    actions: {
        setApplicationLoadingState({commit}: { commit: Commit }, payload: boolean) {
            commit('SWAP_LOADING_STATE', payload);
        },

        setSupportModal({commit}: { commit: Commit }, payload: boolean) {
            commit('SET_SUPPORT_MODAL', payload);
        },

        setAccountModal({commit}: { commit: Commit }, payload: boolean) {
            commit('SET_ACCOUNT_MODAL', payload);
        },

        setIsMobile({commit}: { commit: Commit }, payload: boolean) {
            commit('SET_IS_MOBILE', payload);
        },

        setWindowHeight({commit}: { commit: Commit }, payload: number) {
            commit('SET_WINDOW_HEIGHT', payload);
        }

    }

};
