import axios, {AxiosInstance, AxiosRequestConfig} from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import store from "@/store";
import router from "@/router";

let api_url;

if (process.env.VUE_APP_API_ENVIRONMENT == "PRODUCTION" ||
    process.env.VUE_APP_API_ENVIRONMENT == "STAGING") {
    api_url = process.env.VUE_APP_API_SERVER_URL;
} else {
    api_url = "http://127.0.0.1:8000";
}

const config: AxiosRequestConfig = {
    baseURL: api_url
}

const client: AxiosInstance = axios.create(config);

/*
* Handles automatically refreshing the token
* Catches 401 and 403 errors
* */
const refreshAuthLogic = (failedRequest: any) => client.post('/auth/refresh',
    {}, {withCredentials: true}).then(r => {
    const token = r.data.access;
    store.dispatch('accounts/setUsersToken', token);
    failedRequest.response.config.headers.common = {'Authorization': `Bearer ${token}`};
    client.defaults.headers.common = {'Authorization': `Bearer ${token}`};
    return Promise.resolve();
}).catch(() => {
    router.push({name: 'Login'});
    store.dispatch('notifications/pushNotification', {
        message: 'You\'re not authenticated! Please login to continue.',
        type: 'danger'
    });
    store.dispatch('application/setApplicationLoadingState', false);
});

createAuthRefreshInterceptor(client, refreshAuthLogic, {
    statusCodes: [401, 403],
    pauseInstanceWhileRefreshing: true
});

export default client;
