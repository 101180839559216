import {articleService, logSearchHistory} from "@/store/services/articleService";
import store from "@/store";
import {calculateCountsByDate, findAllSelectedItems, generateFilterOptions} from "@/helpers/filtersHelper";
import axiosMain from "axios";

export const articlesModule = {
    namespaced: true,

    state: {
        status: {},
        isLoading: false,
        currentRequest: null,
        isFetchingArticles: false,
        filterOptions: {},
        filteredBy: 'newest',
        lastUpdatedCount: {
            today_count: null,
            week_count: null,
            month_count: null
        },

        results: {},
        totalResults: 0,
        filteredResults: {},
        resultsByPage: [],
        currentBottom: 0,
        currentPage: 1,
        fetchingNewPage: false,
        allPages: 0,

        // Details for an article modal
        showingModal: false,
        modalDetails: {},
        collection_id:null
    },

    getters: {
        // Getter for if the articles are still being fetched
        getIsLoading: state => {
            return state.isLoading;
        },

        getIsFilteredBy: state => {
            return state.filteredBy;
        },

        getIsFetchingArticles: state => {
            return state.isFetchingArticles;
        },

        getTotalResults: state => {
            return state.totalResults;
        },

        getIsShowingModal: state => {
            return state.showingModal;
        },

        getModalDetails: state => {
            return state.modalDetails;
        },

        getResults: state => {
            return state.results;
        },

        getResultsByPage: state => {
            return state.resultsByPage;
        },

        getCurrentPage: state => {
            return state.currentPage;
        },

        // Returns the bottom pixel of the Articles container DIV
        getCurrentBottom: state => {
            return state.currentBottom;
        },

        getArticlesCount: state => {
            return state.filteredResults.length;
        },

        getAllPages: state => {
            return state.allPages;
        },

        // Returns the filter options
        getFilterOptions: state => {
            return state.filterOptions;
        },

        getLastUpdateCounts: state => {
            return state.lastUpdatedCount;
        },

        getFetchingNewPage: state => {
            return state.fetchingNewPage;
        },

        getCollectionID: state => {
            return state.collection_id;
        }

    },

    mutations: {
        SET_RESULTS(state, payload) {
            state.results = payload;
        },

        ADD_TO_RESULTS(state, payload) {
            let newList = state.results.concat(payload);
            state.results = newList;
        },

        SET_IS_FILTERED_BY(state, payload) {
            state.filteredBy = payload;
        },

        SET_IS_FETCHING_ARTICLES(state, payload) {
            state.isFetchingArticles = payload;
        },

        SET_FILTERED_RESULTS(state, payload) {
            state.filteredResults = payload;
        },

        SET_TOTAL_RESULTS(state, payload) {
            state.totalResults = payload;
        },

        SET_LOADING_STATUS(state, payload) {
            state.isLoading = payload;
        },

        SET_FILTER_OPTIONS(state, payload) {
            state.filterOptions = payload;
        },

        SET_LAST_UPDATE_COUNTS(state, payload) {
            state.lastUpdatedCount = {...payload};
        },

        SET_ALL_PAGES(state, payload) {
            state.allPages = payload;
        },

        SET_CURRENT_REQUEST(state, payload) {
            state.currentRequest = payload;
        },

        SWAP_FILTER_OPTION(state, payload) {
            let tempFilters = {...state.filterOptions};

            // Update the temp filters data
            const itemIndex = tempFilters[payload.key].findIndex(x => x.name === payload.data.name);

            // Find the item by index and update its data
            let itemData = {...tempFilters[payload.key][itemIndex]};
            itemData.selected = !itemData.selected;

            // Update the tempFilters object to contain the new value
            tempFilters[payload.key][itemIndex] = itemData;
            state.filterOptions = tempFilters;
        },

        // Updates our filter options based on state
        SWAP_DATE_FILTER_OPTION(state, payload) {
            let tempFilters = {...state.filterOptions};
            tempFilters.lastUpdated = payload;
            state.filterOptions = tempFilters;
        },

        GENERATE_PAGE_RESULTS(state, payload) {
            const numberToReturn = (25 * payload);
            state.resultsByPage = state.filteredResults.slice(0, numberToReturn);
        },

        SET_CURRENT_BOTTOM(state, payload) {
            state.currentBottom = payload;
        },

        SET_CURRENT_PAGE(state, payload) {
            state.currentPage = payload;
        },

        SET_MODAL_SHOWING(state, payload) {
            state.showingModal = payload;
        },

        SET_MODAL_CONTENT(state, payload) {
            state.modalDetails = payload;
        },

        SET_FETCHING_NEW_PAGE(state, payload) {
            state.fetchingNewPage = payload;
        },

        RESET_ALL_PAGES(state) {
            state.allPages = 0;
        },

        SET_TOTAL_PAGES(state, payload) {
            state.allPages = payload;
        },

        SET_COLLECTION_ID(state, payload) {
            state.collection_id = payload;
        }

    },

    actions: {

        // Gets all articles
        getArticles({commit, state}) {

            // Cancels the current request if exists
            if (state.currentRequest) {
                state.currentRequest.cancel();
            }

            // Create a token for cancelling requests
            const cancelTokenSource = axiosMain.CancelToken.source();
            commit('SET_CURRENT_REQUEST', cancelTokenSource);

            commit('SET_IS_FETCHING_ARTICLES', true);
            let start_time = new Date().getTime();
            store.dispatch('filters/getFilters');
            store.dispatch('tooltip/update_tooltips');
            articleService.getArticles(cancelTokenSource, state.currentPage)
                .then(response => {
                    commit('SET_CURRENT_REQUEST', null);
                    // Builds the response in the format we require for the front-end
                    let responseData = response.data.results;

                    responseData.forEach((item, index) => {
                        let alertType = ''
                        if (item.alert_type) {
                            alertType = item.alert_type;

                        }

                        responseData[index].alert_type = alertType;

                    });

                    commit('SET_RESULTS', responseData);
                    commit('SET_TOTAL_RESULTS', response.data.count);
                    commit('SET_ALL_PAGES', response.data.all_pages);
                    commit('SET_IS_FETCHING_ARTICLES', false);
                })
                .then(() => {
                    let request_time = new Date().getTime() - start_time;
                    console.log(`Total time taken: ${request_time}`);
                })
                .catch(() => {
                    commit('SET_CURRENT_REQUEST', null);
                });
        },

        searchArticles({commit, state}) {

            // Cancels the current request if exists
            if (state.currentRequest) {
                state.currentRequest.cancel();
            }

            // Create a token for cancelling requests
            const cancelTokenSource = axiosMain.CancelToken.source();
            commit('SET_CURRENT_REQUEST', cancelTokenSource);

            store.dispatch('application/setApplicationLoadingState', false);
            commit('SET_IS_FETCHING_ARTICLES', true);
            let start_time = new Date().getTime();
            const allFiltersOnSidebar = store.getters["filters/getSelectedFilters"];
            const allSelectedItems = findAllSelectedItems(allFiltersOnSidebar);

            const searchValue = store.getters["search/getSearchValue"];
            const searchFilters = store.getters["search/getAllFilters"];
            const filteredBy = state.filteredBy;
            const collection_id = state.collection_id
            store.dispatch('filters/getFilters');
            articleService.getArticlesByFilters(cancelTokenSource, state.currentPage, searchFilters, searchValue, allSelectedItems, filteredBy)
                .then(response => {

                    commit('SET_CURRENT_REQUEST', null);

                    // Builds the response in the format we require for the front-end
                    let responseData = response.data.results;

                    responseData.forEach((item, index) => {

                        let alertType = ''

                        if (item.alert_type) {
                            alertType = item.alert_type;
                        }

                        responseData[index].alert_type = alertType;

                    });
                    if(collection_id!=null){
                       articleService.updateCountForCollection(collection_id,response.data.count,cancelTokenSource)
                    }
                    commit('SET_RESULTS', responseData);
                    commit('SET_TOTAL_RESULTS', response.data.count);
                    commit('SET_ALL_PAGES', response.data.all_pages);
                    commit('SET_IS_FETCHING_ARTICLES', false);
                    commit('SET_COLLECTION_ID', null);
                })
                .then(() => {
                    logSearchHistory();
                    let request_time = new Date().getTime() - start_time;
                    console.log(`Total time taken: ${request_time}`);
                })
                .catch(() => {
                    commit('SET_CURRENT_REQUEST', null);
                });
        },

        // Used for pagination on Articles
        searchAdditionalArticles({commit, state}, pageNumber) {

            // Cancels the current request if exists
            if (state.currentRequest) {
                state.currentRequest.cancel();
            }

            // Create a token for cancelling requests
            const cancelTokenSource = axiosMain.CancelToken.source();
            commit('SET_CURRENT_REQUEST', cancelTokenSource);

            commit('SET_FETCHING_NEW_PAGE', true);
            let start_time = new Date().getTime();
            const allFiltersOnSidebar = store.getters["filters/getSelectedFilters"];
            const allSelectedItems = findAllSelectedItems(allFiltersOnSidebar);

            const searchValue = store.getters["search/getSearchValue"];
            const searchFilters = store.getters["search/getAllFilters"];
            const filteredBy = state.filteredBy;
            articleService.getArticlesByFilters(cancelTokenSource, pageNumber, searchFilters, searchValue, allSelectedItems, filteredBy)
                .then(response => {
                    commit('SET_CURRENT_REQUEST', null);
                    // Builds the response in the format we require for the front-end
                    let responseData = response.data.results;

                    responseData.forEach((item, index) => {
                        let alertType = ''
                        if (item.alert_type) {
                            alertType = item.alert_type;
                        }

                        responseData[index].alert_type = alertType;
                    });

                    commit('ADD_TO_RESULTS', responseData);
                    commit('SET_IS_FETCHING_ARTICLES', false);
                    commit('SET_FETCHING_NEW_PAGE', false);
                })
                .then(() => {
                    let request_time = new Date().getTime() - start_time;
                    console.log(`Total time taken: ${request_time}`);
                })
                .catch(() => {
                    commit('SET_CURRENT_REQUEST', null);
                    commit('SET_FETCHING_NEW_PAGE', false);
                });
        },

        setIsFetchingArticles({commit}, value) {
            commit('SET_IS_FETCHING_ARTICLES', value);
        },

        // Sets the articles from an external source
        setArticlesFromExternalSource({commit}, value) {
            commit('SET_RESULTS', value);
        },

        // Sets the loading state in the articles container
        setLoadingFromExternalSource({commit}, value) {
            commit('SET_LOADING_STATUS', value);
        },

        setFetchingArticles({commit}, value) {
            commit('SET_IS_FETCHING_ARTICLES', value);
        },

        // Generates and sets the filtering options for the sidebar
        generateAndSetFilterOptions({commit, state}) {
            try {
                const filter_options = generateFilterOptions(state.results);
                const last_updated_counts = calculateCountsByDate(state.results);
                commit('SET_CURRENT_BOTTOM', 0);
                commit('SET_CURRENT_PAGE', 1);
                commit('SET_FILTER_OPTIONS', filter_options);
                commit('SET_LAST_UPDATE_COUNTS', last_updated_counts);
            } catch (e) {
                // Do nothing for the time
            }
        },

        generateLastUpdatedFilters({commit, state}) {
            const last_updated_counts = calculateCountsByDate(state.results);
            commit('SET_LAST_UPDATE_COUNTS', last_updated_counts);
        },

        updateSortingOption({commit}, value) {
            commit('SET_IS_FILTERED_BY', value);
            store.dispatch('articles/searchArticles');
            commit('SET_CURRENT_PAGE', 1);
        },

        updateCurrentPage({commit}, value) {
            commit('SET_CURRENT_PAGE', value);
        },

        updateModalShowingState({commit}, value) {
            commit('SET_MODAL_SHOWING', value);

            // If we are hiding the modal clear the content for it as well
            if (value === false) {
                commit("SET_MODAL_CONTENT", {});
            }
        },

        resetAllPages({commit}) {
            commit('RESET_ALL_PAGES');
        },

        updateModalContent({commit}, value) {
            commit('SET_MODAL_CONTENT', value);
        },

        setTotalResults({commit}, value) {
            commit('SET_TOTAL_RESULTS', value);
        },

        setFetchingNewPage({commit}, value) {
            commit('SET_FETCHING_NEW_PAGE', value);
        },

        setAllPagesCount({commit}, value) {
            commit('SET_ALL_PAGES', value);
        },

        setCollectionId({commit},value){
            commit('SET_COLLECTION_ID', value);
        }

    }
};
