import axios from "@/axios";
import {ResetPassword} from "@/types/account/ResetPassword";

export default async function resetPassword(userReset: ResetPassword) {
    // try {
        return await axios.patch('/accounts/user/reset-password', {
            uid: userReset.uid,
            token: userReset.token,
            password1: userReset.password1,
            password2: userReset.password2
        }, { withCredentials: true })
    // } catch (err) {
    //     return err;
    // }
}
