import Vue from 'vue'
import Vuex from 'vuex'

/* Imports all external modules */
import {dashboardModule} from "@/store/modules/dashboardModule.ts";
import {notificationsModule} from "@/store/modules/notificationsModule.ts";
import {messageModule} from "@/store/modules/messageModule";
import {accountsModule} from "./modules/accountsModule";
import {articlesModule} from "@/store/modules/articlesModule";
import {filtersModule} from "@/store/modules/filtersModule";
import {searchFilters} from "@/store/modules/searchFilters";
import {filtersSidebarModule} from "@/store/modules/filtersSidebarModule.ts";
import {collectionsModule} from "@/store/modules/collectionsModule";
import {editSavedSearchModule} from "@/store/modules/editSavedSearchModule";
import {applicationModule} from "@/store/modules/applicationModule.ts";
import {foldersModule} from "@/store/modules/foldersModule";
import {tooltipModule} from "@/store/modules/tooltip";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        dashboard: dashboardModule,
        notifications: notificationsModule,
        messages: messageModule,
        accounts: accountsModule,
        articles: articlesModule,
        filters: filtersModule,
        search: searchFilters,
        folders: foldersModule,
        sidebar: filtersSidebarModule,
        collections: collectionsModule,
        editSavedSearch: editSavedSearchModule,
        application: applicationModule,
        tooltip: tooltipModule
    }
});
