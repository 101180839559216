import axios from "@/axios";
import {SupportMessage} from "@/types/account/SupportMessage";


export default async function sendSupportMessage(supportMessage: SupportMessage) {
    try {
        return await axios.post('/accounts/support', {
            email: supportMessage.email,
            subject: supportMessage.subject,
            body: supportMessage.body
        }, { withCredentials: true })
    } catch (err) {
        return err;
    }
}
