import axios from '@/axios';
import store from "@/store";


export const filtersService = {
    getFiltersForQuery
}

function getFiltersForQuery(cancelToken, searchParams, searchValue, selectedItems, filteredBy) {
    let url = `/articles/filter-options`;
    let collectionItem = store.getters["collections/returnCollectionItem"];
    let current_url = new URL(window.location.href);
    var last_viewed = current_url.searchParams.get("last_viewed");

    if(collectionItem){
        url = `/articles/filter-options?last_viewed=${collectionItem.modified}`;
    }
    else if (last_viewed) {
        url = `/articles/filter-options?last_viewed=${last_viewed}`;
    }

    return axios.post(url, {
        searchFilters: {...searchParams},
        searchValue: searchValue,
        filters: selectedItems,
        sortBy: filteredBy
    },{withCredentials: true, cancelToken: cancelToken.token })
        .then(response => {
            return response;
        });
}
