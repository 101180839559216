import axios from "@/axios";

export default async function sendVerificationEmail(email: string) {
    try {
        return await axios.post('/accounts/user/send/verify-email', {
            email: email
        }, { withCredentials: true });
    } catch (err) {
        return err;
    }
}
