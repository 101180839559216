import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/scss/main.scss';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'chart.js/dist/Chart.bundle.min.js';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
// Axios
import axios from "@/axios";
// Vue Select
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
// Custom tracking plugin => Globally accessible from this.$tracking
import { TrackingHelper } from "@/helpers/trackingHelper"

Vue.config.productionTip = false;

Vue.prototype.$http = axios;
// End of Axios
Vue.component('v-select', vSelect);
// End of Vue Select

//Switch sentry on the basis of project environment
if (process.env.VUE_APP_API_ENVIRONMENT == "STAGING") {

    //Sentry Init
    Sentry.init({
        Vue,
        dsn: "https://5c24c2d9d6054ac6b4df33e4e7c96170@o851435.ingest.sentry.io/5893355",
        tracingOptions: {
            // To track child component actions
            trackComponents: true,
        },
        integrations: [new Integrations.BrowserTracing()],
        // Can toggle if required in production
        tracesSampleRate: 1.0,
    });
} else if (process.env.VUE_APP_API_ENVIRONMENT == "PRODUCTION") {
    //Sentry Init
    Sentry.init({
        Vue,
        dsn: "https://edcd050a014c4880a9b3405b4eec0bbc@o851435.ingest.sentry.io/5893358",
        tracingOptions: {
            // To track child component actions
            trackComponents: true,
        },
        integrations: [new Integrations.BrowserTracing()],
        // Can toggle if required in production
        tracesSampleRate: 1.0,
    });
}

const EventBus = new Vue();
const app = new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');

export const trackingHelper = new TrackingHelper(app);
Vue.prototype.$tracking = trackingHelper;
// End of custom tracking plugin
