import axios from "@/axios.ts";
import store from "@/store";
import { checkUserAuthentication } from "@/store/services/accountService";
import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter)

const routes = [
    {
        // path: '/dashboard',
        // component: () => import('@/layouts/Dashboard'),
        // beforeEnter: (to, from, next) => {
        //     // Check if the user is currently authenticated with the back-end before allowing access
        //     // to any of the children routes
        //     store.dispatch('application/setApplicationLoadingState', true);
        //     checkUserAuthentication()
        //         .then(response => {
        //             // Parse the status code
        //             const responseCode = parseInt(response.status);
        //             if (responseCode === 200) {
        //                 next();
        //                 store.dispatch('accounts/setAccountDetails', response.data);
        //                 store.dispatch('application/setApplicationLoadingState', false);
        //                 store.dispatch('accounts/setSearchHighlight', response.data.search_highlight);

        //             } else if (responseCode === 401) {
        //                 axios.post('/auth/refresh', {}, {withCredentials: true})
        //                     .then(response => {
        //                         if (response.status === 200) {
        //                             next();
        //                             store.dispatch('application/setApplicationLoadingState', false);
        //                         }
        //                     }).catch(() => {
        //                     console.log('trying to refresh')
        //                 })
        //             } else if (responseCode === 426) {
        //                 store.dispatch('notifications/pushNotification', {
        //                     message: 'You need to upgrade your account!',
        //                     type: 'danger'
        //                 });
        //                 next({name: 'Login'});
        //                 store.dispatch('application/setApplicationLoadingState', false);
        //             } else {
        //                 store.dispatch('notifications/pushNotification', {
        //                     message: 'You need to login to access the dashboard!',
        //                     type: 'danger'
        //                 });
        //                 next({name: 'Login'});
        //                 store.dispatch('application/setApplicationLoadingState', false);
        //             }

        //         })
        //         .catch(err => {
        //             store.dispatch('notifications/addNotification', {
        //                 message: "You're not authenticated! Please login to continue",
        //                 type: 'danger'
        //             })
        //             store.dispatch('application/setApplicationLoadingState', false);
        //             next({name: 'Login'});
        //         });
        // },
        path: '/dashboard',
        component: () => import('@/layouts/Dashboard'),
        beforeEnter: (to, from, next) => {
            // Check if the user is currently authenticated with the back-end before allowing access
            // to any of the children routes
            store.dispatch('application/setApplicationLoadingState', true);
            checkUserAuthentication()
                .then(response => {
                    const responseCode = parseInt(response.status);
                    if (responseCode === 200) {
                        const accountDetails = response.data;
                        store.dispatch('accounts/setAccountDetails', accountDetails);
                        store.dispatch('application/setApplicationLoadingState', false);
                        store.dispatch('accounts/setSearchHighlight', accountDetails.search_highlight);

                        if (accountDetails.license_type === 'stripe') {
                            if (accountDetails.stripe_status === 'active') {
                                next();
                            } else {
                                // Redirect to a subscription management page if not active or no subscription
                                next({ name: 'SubscriptionManagement' });
                            }
                        } else {
                            next();
                        }
                    } else if (responseCode === 401) {
                        axios.post('/auth/refresh', {}, { withCredentials: true })
                            .then(response => {
                                if (response.status === 200) {
                                    next();
                                    store.dispatch('application/setApplicationLoadingState', false);
                                }
                            }).catch(() => {
                                console.log('trying to refresh')
                            })
                    } else if (responseCode === 426) {
                        store.dispatch('notifications/pushNotification', {
                            message: 'You need to upgrade your account!',
                            type: 'danger'
                        });
                        next({ name: 'Login' });
                        store.dispatch('application/setApplicationLoadingState', false);
                    } else {
                        store.dispatch('notifications/pushNotification', {
                            message: 'You need to login to access the dashboard!',
                            type: 'danger'
                        });
                        next({ name: 'Login' });
                        store.dispatch('application/setApplicationLoadingState', false);
                    }

                })
                .catch(err => {
                    store.dispatch('notifications/addNotification', {
                        message: "You're not authenticated! Please login to continue",
                        type: 'danger'
                    });
                    store.dispatch('application/setApplicationLoadingState', false);
                    next({ name: 'Login' });
                });
        },
        children: [
            {
                path: '/',
                name: 'Home',
                // TODO: Remove once charts are active
                beforeEnter: (to, from, next) => {
                    next('/dashboard/articles');
                }
                // component: () => import('@/views/dashboard/Index')
            },

            {
                path: 'articles',
                name: 'Articles',
                component: () => import('@/views/dashboard/Articles')
            },
            {
                path: 'folders',
                name: 'AllFolders',
                component: () => import('@/views/dashboard/AllFolders')
            },
            {
                path: 'folders/:id',
                name: 'Folder',
                component: () => import('@/views/dashboard/Folder')
            },
            {
                path: 'account',
                name: 'Account',
                component: () => import('@/views/dashboard/Account')
            },
            {
                path: 'support',
                name: 'Support',
                component: () => import('@/views/dashboard/Support')
            },
            {
                path: 'support-confirmation',
                name: 'SupportConfirmation',
                component: () => import('@/views/dashboard/SupportConfirmation')
            },


        ]
    },
    {
        path: 'logout',
        name: 'Logout',
        component: () => import('@/views/dashboard/Logout')
    },
    {
        path: '/subscription-management',
        // name: 'SubscriptionManagement',
        component: () => import('@/layouts/Dashboard'),
        children: [
            {
                path: '/',
                name: 'SubscriptionManagement',
                component: () => import('@/views/SubscriptionManagement.vue')
            },
        ]
        //     }
        // component: () => import('@/views/SubscriptionManagement.vue'),
        // // Reuse the Dashboard layout here
        // meta: { layout: 'dashboard' }
    },
    {
        path: '',
        component: () => import('@/layouts/FullPage'),
        children: [
            {
                path: '/',
                beforeEnter: (to, from, next) => {
                    next('/dashboard');
                }
            },
            {
                path: '/login',
                name: 'Login',
                component: () => import('@/views/Login'),
                beforeEnter: (to, from, next) => {
                    var user = store.getters['accounts/getCurrentUser']
                    console.log(user)
                    if (user.email) {
                        return next('/dashboard/articles')
                    }
                    return next()
                }

            },

            {
                path: '/signup',
                name: 'Signup',
                component: () => import('@/views/Signup')
            },
            {
                path: '/draw',
                name: 'Draw',
                component: () => import('@/views/draw')
            },
            {
                path: '/signup/:access_code',
                name: 'Signup',
                component: () => import('@/views/SignupWithAccessCode.vue')
            },
            {
                path: '/link/telemetry/get-sort-url/:artical_id/:user_id/:camp',
                name: 'Link',
                component: () => import('@/views/Link')
            },
            {
                path: '/confirm-email',
                name: 'ConfirmEmail',
                component: () => import('@/views/SignupConfirmEmail')
            },
            {
                path: '/reset-password',
                name: 'SendPasswordResetEmail',
                component: () => import('@/views/ResetPassword')
            },
            {
                path: '/verify/account/:uid/:token',
                name: 'VerifyAccount',
                component: () => import('@/views/VerifyAccount')
            },
            {
                path: '/reset-password/account/:uid/:token',
                name: 'ConfirmPasswordReset',
                component: () => import('@/views/ConfirmPasswordReset')
            },
            {
                path: '/terms-of-service',
                name: 'TermsOfService',
                component: () => import('@/views/TermsOfService')
            },
            {
                path: '/ai-disclaimer',
                name: 'AiDisclaimer',
                component: () => import('@/views/AiDisclaimer')
            },
            {
                path: '/release-notes',
                name: 'ReleaseNotes',
                component: () => import('@/views/ReleaseNotes')
            },
            {
                path: '/privacy-policy',
                name: 'PrivacyPolicy',
                component: () => import('@/views/PrivacyPolicy')
            }
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router;
