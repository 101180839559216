import {Commit} from "vuex";


export const filtersSidebarModule = {
    namespaced: true,

    state: {
        isOpen: false
    },

    getters: {
        getSidebarState: (state: { isOpen: boolean }) => {
            return state.isOpen;
        }
    },

    mutations: {
        SWAP_SIDEBAR_STATE(state: { isOpen: boolean }) {
            state.isOpen = !state.isOpen;
        },

        MANUALLY_SET_SIDEBAR_STATE(state: { isOpen: boolean }, payload: boolean) {
            state.isOpen = payload;
        }

    },

    actions: {
        openOrCloseSidebar({commit}: { commit: Commit }) {
            commit('SWAP_SIDEBAR_STATE');
        },

        manuallySetSidebarState({commit}: { commit: Commit }, payload: boolean) {
            commit('MANUALLY_SET_SIDEBAR_STATE', payload);
        }

    }

}
