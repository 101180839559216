import axios from '@/axios';
import FileDownload from 'js-file-download';

export const articleCollectionsService = {
    create,
    list,
    retrieve,
    modify,
    destroy,
    getArticlesFromCollection,
    exportCollection
}

export {
    getAllUserCollections,getAllUserCollectionsCounts,getCuratedCollections,getCompanyCollections
}


function create(name, searchFilters, pinned) {
    console.log(name, searchFilters, pinned);
    return axios.post('/collections',
        JSON.stringify({name: name, search_filters: searchFilters, pinned: pinned}),
        {withCredentials: true, headers: {'Content-Type': 'application/json'}})
        .then(response => {
            console.log(response);
            return response;
        }).catch(error => {
            console.log(error)
        });
}


function getAllUserCollections() {
    return axios.get('/collections/counts', { withCredentials: true })
        .then(response => {
           return response;
        });
}

function getCuratedCollections() {
    return axios.get('/collections/get_curated_count', { withCredentials: true })
        .then(response => {
           return response;
        });
}
function getCompanyCollections() {
    console.log("Here")
    return axios.get('/collections/get_company_count', { withCredentials: true })
        .then(response => {
           return response;
        });
}

function getAllUserCollectionsCounts() {
    return axios.get('/collections/counts?fetchCount=True', { withCredentials: true })
        .then(response => {
            return response;
        });
}


function list() {
    return axios.get('/collections', {withCredentials: true})
        .then(response => {
            return response;
        });
}


function retrieve(pk) {
    return axios.get(`/collections/${pk}`, {withCredentials: true})
        .then(response => {
            return response;
        });
}


function modify(pk, modifications) {
    return axios.patch(`/collections/${pk}`,
        JSON.stringify(modifications),
        {withCredentials: true, headers: {'Content-Type': 'application/json'}})
        .then(response => {
            return response;
        });
}


function destroy(pk) {
    return axios.delete(`/collections/${pk}`,
        {withCredentials: true})
        .then(response => {
            return response;
        });
}


function getArticlesFromCollection(pk) {
    return axios.get(`/collections/${pk}/articles`, {withCredentials: true})
        .then(response => {
            return response;
        })
}


function exportCollection (collection_id, filename) {
    console.log(collection_id);
    return axios.post(`/collections/export`, 
        JSON.stringify({'collection_id': collection_id, 'filename': filename}), 
        {withCredentials: true, responseType: 'arraybuffer', headers: {'Content-Type': 'application/json'}})
        .then(response => {
            return FileDownload(response.data, filename+'.xlsx' ? filename : 'RegAlytics.xlsx');
        }, error => {
            console.log('err', error.response)
            return error.response; 
        }).catch(error => {
            console.log('err', error.response)
            return error.response;
        })
}
