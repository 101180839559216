import {DashboardStyle} from "@/types/dashboard/DashboardStyle";
import {Commit} from "vuex";
import {LayoutStyle} from "@/types/dashboard/LayoutStyle";

export const dashboardModule = {
    namespaced: true,

    state: {
        dashboardStyle: {
            pinned: false,
            class: 'dashboard-wrapper-min',
            type: 'min'
        } as DashboardStyle
    },

    getters: {

        getDashboardStyle: (state: { dashboardStyle: DashboardStyle; }) => {
            return state.dashboardStyle;
        }

    },

    mutations: {

        SET_DASHBOARD_STYLE_CLASS(state: { dashboardStyle: DashboardStyle }, payload: string) {
            state.dashboardStyle.class = payload;
        },

        SET_DASHBOARD_STYLE_TYPE(state: { dashboardStyle: DashboardStyle }, payload: string) {
            state.dashboardStyle.type = payload;
        },

        SET_DASHBOARD_PINNED(state: { dashboardStyle: DashboardStyle }, payload: boolean) {
            state.dashboardStyle.pinned = payload;
        }


    },

    actions: {

        // Sets the dashboard layout style for the Vertical navigation
        setDashboardStyleClass({commit}: { commit: Commit }, value: LayoutStyle) {
            const styles = ['dashboard-wrapper-full', 'dashboard-wrapper-min'];
            switch (value) {
                case LayoutStyle.MAX:
                    commit('SET_DASHBOARD_STYLE_CLASS', styles[0]);
                    commit('SET_DASHBOARD_STYLE_TYPE', 'max');
                    break;
                case LayoutStyle.MIN:
                    commit('SET_DASHBOARD_STYLE_CLASS', styles[1]);
                    commit('SET_DASHBOARD_STYLE_TYPE', 'min');
                    break;
                default:
                    break;
            }
        },

        setDashboardPinned({commit}: { commit: Commit }, value: boolean) {
            commit('SET_DASHBOARD_PINNED', value);
        }

    },

}
