import { v4 as uuidv4 } from 'uuid';
import store from '@/store';
import { Notification } from "@/types/dashboard/Notification";
import { Commit } from "vuex/types";

export const notificationsModule = {
    namespaced: true,

    state: {
        notifications: [] as Array<Notification>,
    },

    getters: {

        getAllNotifications: (state: { notifications: Array<Notification> }) => {
            return state.notifications;
        }

    },

    mutations: {

        ADD_TO_NOTIFICATIONS(state: { notifications: Array<Notification> }, payload: Notification) {
            state.notifications.unshift({
                id: payload.id,
                message: payload.message,
                type: payload.type,
                autoHide: payload.autoHide || true
            });
        },

        REMOVE_FROM_NOTIFICATIONS(state: { notifications: Array<Notification> }, notificationId: string) {
            const notificationIndex = state.notifications.findIndex(x => x.id === notificationId);
            state.notifications.splice(notificationIndex, 1);
        },

        CLEAR_NOTIFICATIONS(state: { notifications: Array<Notification> }) {
            state.notifications = []
        },

    },

    actions: {
        pushNotification({ commit }: { commit: Commit }, payload: Notification) {
            store.dispatch('notifications/addNotification', payload)
        },

        addNotification({ commit }: { commit: Commit }, payload: Notification) {
            const notificationId = uuidv4();
            payload.id = notificationId;
            commit('ADD_TO_NOTIFICATIONS', payload);
            let autoHide = true;
            if (payload.autoHide === false) {
                autoHide = false;
            }

            // Auto-removes the notification after 3 seconds
            if (autoHide) {
                return new Promise<void>(() => {
                    setTimeout(() => {
                        commit('REMOVE_FROM_NOTIFICATIONS', notificationId);
                    }, 3000);
                })
            }

        },

        removeNotification({ commit }: { commit: Commit }, notificationId: string) {
            commit('REMOVE_FROM_NOTIFICATIONS', notificationId);
        },

        clearNotification({ commit }: { commit: Commit }) {
            commit('CLEAR_NOTIFICATIONS');
        }

    }
}
